export const environment = {
  production: false,
  staging: true,
  dev: false,
  siteUrl: 'https://mynitel.stage.nitelusa.com',
  apiUrl: 'https://mynitel.stage.nitelusa.com/api/v2',
  mocks: false,
  entra_tenant_id: "3b07c292-8989-432b-84cf-ac7772d4cb9b",
  entra_spa_client_id: "1ac14a9e-453f-4926-a91b-df192ccd9f49",
  entra_nitelapi_scopes: "https://mynitel.onmicrosoft.com/myniteltest/readwrite"
};
